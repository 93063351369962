
































import Vue from 'vue';

export default Vue.extend({
  name: 'loading',
  components: {
  },
  data: () => ({

  }),
  computed: {

  },
  methods: {

  }
})
