<template>
  <div class="certRoot">

    <loading v-if="loading"/>

    <div class="container" :style="{ marginTop: getScreenWidth > 375 ? '10rem' : '8rem'}" v-else>
      <span class="el-icon-close close" @click="goBack"></span>

      <img src="@/assets/loading/cert.png" width="86" height="97" class="cert"/>

      <h2 class="title">{{ $t("feture.bck_dpt") }}</h2>
      <h5 class="subtitle">{{ $t('feture.prd_code') }}：{{ vid }}</h5>
      <hr class="line">

      <div class="info">
        <p>{{ $t('feture.upload_id') }}：{{ user }}</p>
        <p>{{ $t('feture.upload_time')}}：{{ meta.blockTimestamp }}</p>

        <div class="block">
          <p>{{ $t('feture.block') }}：</p>
          <span>{{ meta.blockNum }}</span>
        </div>

        <div class="block">
          <p>{{ $t('feture.bck_time') }}：</p>
          <span>{{ meta.blockTimestamp }}</span>
        </div>

        <div class="block">
          <p>{{ $t('feture.trace_num') }}：</p>
          <span :class="getScreenWidth <= 375 ? 'ellipsis' :''">{{ meta.blockID }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import { sleep } from '@/utils/common';
import { dateFormat } from '@/utils/index';
import { shortTxid } from '@/utils/bless';
import VeTrueApi from '@/api/vetrue';
import Loading from '@/feature/components/chainLoading.vue';

export default Vue.extend({
  name: 'Cert',
  components: {
    Loading,
  },
  computed: {
    getScreenWidth() {
      const width = document.documentElement.clientWidth || document.body.clientWidth;
      return width;
    },
  },
  data: () => ({
    loading: true,
    meta: {
      blockNum: 0,
      blockID: '',
      blockTimestamp: new Date().getTime(),
    },
    txId: '',
    vid: '',
    user: ''
  }),
  mounted() {
    const txId = this.$route.query.txId || '';
    const vid = this.$route.query.vid || '';
    const user = this.$route.query.user || '';
    this.user = user;
    this.getTransactionsInfo(txId, vid);
  },
  methods: {
    async getTransactionsInfo(txId, vid) {
      this.loading = true;
      try {
        const data = await VeTrueApi.getTransactionsInfo(txId);
        const blockNum = R.pathOr(0, ['data', 'meta', 'blockNumber'])(data);
        const timestamp = R.pathOr(new Date().getTime(), ['data', 'meta', 'blockTimestamp'])(data);
        const blockID = R.pathOr(0, ['data', 'meta', 'blockID'])(data);

        this.meta.blockNum = blockNum;
        this.meta.blockTimestamp = dateFormat(timestamp * 1000);
        this.meta.blockID = blockID;
        this.vid = shortTxid(vid, 8, 8);

        await sleep(1500);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message({ message: this.$t('feture.failed_cert_deposit') + error.message, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }
    },
    goBack() {
      this.$router.push({
        path: '/remark',
        query:{ vid: this.$route.query.vid, from: 'cert' },
      })
    }
  }
})
</script>

<style lang="scss" scoped>

.certRoot {
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
}

.container {
  position: relative;
  width: 90%;
  max-height: 80%;
  margin: 80px auto;
  color: #EBEDF5;
  background: #24252B;
  opacity: 0.8;
  border-radius: 8px;
  text-align: center;
  padding: 0 25px;
  padding-bottom: 30px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);
}

.cert {
  position: absolute;
  height: 97px;
  width: 86px;
  top: -50px;
  left: 50%;
  margin-left: -43px;
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.title {
  padding-top: 48px;
  color: #EBEDF5;
  line-height: 37px;
  font-size: 16px !important;
  font-weight: 600;
}

.subtitle {
  font-size: 12px;
  font-weight: 600;
  color: #EBEDF5;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.line {
  height: 1px;
  border-color: rgba(255, 255, 255, 0.1500);
  margin-bottom: 12px;
}

.info {
  font-size: 14px;
  color: rgba(255, 255, 255, .5);
  text-align: left;
  background: #24252B !important;
}

.block {
  margin-bottom: 20px;
  & > p {
    margin-bottom: 10px;
  }
  & > span {
    font-size: 14px;
    display: block;
    width: 100%;
    color: #EBEDF5;
    border-radius: 4px;
    padding: 10px 10px;
    word-break: break-all;
    background: rgba(255, 255, 255, 0.1000);
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close {
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: -60px;
}

</style>
